import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { AuthContext } from './AuthContext'
import { decrypt, encrypt } from '@/utils'
import { AUTHENTICATED, AUTHENTICATED_USER, LOGGED_IN, TOKEN, RECOVERY_ADVERTISE, LITE_ADVERTISE } from '@/utils/constants'
import Cookies from 'js-cookie'
import { authReducer } from './authReducer'
import { authTypes } from '@/types/authTypes'
import { useRouter } from 'next/router'
import { post } from '@/services/axios/api'
import { sweetError, sweetLoading, sweetSuccess } from '@/utils/sweetalert2'
import { loginAsync, registerAsync, resendCodeAsync, skipValidationAsync, verifyCodeAsync, registeSpei, registeOxxo } from '@/services/axios/guest/authenticate'
import { updateAsync } from '@/services/axios/authenticated/account'
import { changePasswordAsync } from '@/services/axios/authenticated/recover'

export const initial = () => {
  const user = (Cookies.get(AUTHENTICATED_USER)) ? JSON.parse(decrypt(Cookies.get(AUTHENTICATED_USER))) : {}
  return {
    isLoggedIn: decrypt(Cookies.get(AUTHENTICATED)) === LOGGED_IN,
    user
  }
}

const prefix = '/system'

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, {}, initial)
  const router = useRouter()

  const sweetLoadingRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)

  const [currentBranch, setCurrentBranch] = useState(null)
  const [responseLogOx, setResponseLogOx] = useState(false)
  const [responseLogSp, setResponseLogSp] = useState(false)

  const onOxxo = async (formData, id, setOrder) => {
    registeOxxo(formData, id)
      .then((response) => {
        setOrder(response.data)
      }).catch((e) => {
        sweetError('', e)
      }).finally(() => {
        setIsLoading(false)
      })
  }
  const onSpei = async (formData, id, setOrder) => {
    registeSpei(formData, id)
      .then((response) => {
        setOrder(response.data)
      }).catch((e) => {
        sweetError('', e)
      }).finally(() => {
        setIsLoading(false)
      })
  }

  const onSubmit = useMemo(() => {
    const handleRegisterSubmit = (data) => {
      setIsLoading(true)
      const { country: { id: country }, terms: termsBoolean, ...rest } = data
      const terms = (termsBoolean) ? 1 : 0
      const formData = {
        country,
        terms,
        ...rest
      }
      registerAsync(formData)
        .then((response) => {
          const {
            data: {
              user,
              token: {
                access_token: accessToken
              }
              // membership: { id }
            }
          } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
          Cookies.set(TOKEN, encrypt(accessToken))
          // console.log('here');
          Cookies.set(RECOVERY_ADVERTISE, true)
          // Cookies.set(LITE_ADVERTISE, id)
          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          router.replace('/register/validation')
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          setIsLoading(false)
        })
    }
    const handleSkipValidationSubmit = () => {
      setIsLoading(true)
      skipValidationAsync()
        .then((response) => {
          const { data: { user, token: { access_token: accessToken } } } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
          Cookies.set(TOKEN, encrypt(accessToken))
          // console.log('here');
          Cookies.set(RECOVERY_ADVERTISE, true)
          // Cookies.set(LITE_ADVERTISE, id)
          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          router.replace('/register/membership')
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          setIsLoading(false)
        })
    }
    const handleVerifyCodeSubmit = (data) => {
      const formData = {
        code: `${data.codeone}${data.codetwo}${data.codethree}${data.codefour}${data.codefive}`
      }
      setIsLoading(true)
      verifyCodeAsync(formData)
        .then((response) => {
          const { data: { user, token: { access_token: accessToken } } } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
          Cookies.set(TOKEN, encrypt(accessToken))
          // console.log('here');
          Cookies.set(RECOVERY_ADVERTISE, true)
          // Cookies.set(LITE_ADVERTISE, 1)
          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          router.replace('/register/membership')
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          setIsLoading(false)
        })
    }
    const handleChangePassword = (data) => {
      changePasswordAsync(data)
        .then((response) => {
          const { data: { user, token: { access_token: accessToken }, membership: { id } } } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
          Cookies.set(TOKEN, encrypt(accessToken))
          // console.log('here');
          Cookies.set(RECOVERY_ADVERTISE, id)
          Cookies.set(LITE_ADVERTISE, id)
          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          router.replace(`${prefix}/dashboard`)
        }).catch((e) => {
          sweetError('', e)
        })
    }
    const onFreeMembershipSubmit = (data) => {
      sweetLoadingRef.current = sweetLoading('', 'Estamos procesando tu solicitud de adquisición de membresía. ')
      post(`user/register/membership/${data}/free`)
        .then((response) => {
          const { data: { user, token: { access_token: accessToken } } } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
          Cookies.set(TOKEN, encrypt(accessToken))
          // console.log('here');
          Cookies.set(RECOVERY_ADVERTISE, true)
          Cookies.set(LITE_ADVERTISE, 1)
          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          router.replace(`${prefix}/dashboard`)
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          sweetLoadingRef.current.close()
          sweetLoadingRef.current = null
        })
    }
    const onTryMembershipSubmit = (data) => {
      sweetLoadingRef.current = sweetLoading('', 'Estamos procesando tu solicitud de adquisición de membresía. ')
      post(`user/register/membership/${data}/try`)
        .then((response) => {
          const { data: { user, token: { access_token: accessToken }, membership: { id } } } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
          Cookies.set(TOKEN, encrypt(accessToken))
          // console.log('here');
          Cookies.set(RECOVERY_ADVERTISE, id)
          Cookies.set(LITE_ADVERTISE, id)
          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          router.replace(`${prefix}/dashboard`)
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          sweetLoadingRef.current.close()
          sweetLoadingRef.current = null
        })
    }
    const handleLoginSubmit = (data) => {
      setIsLoading(true)
      loginAsync(data)
        .then((response) => {
          const { data: { user, token: { access_token: accessToken }, membership: { id } } } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
          Cookies.set(TOKEN, encrypt(accessToken))
          // console.log(LITE_ADVERTISE, id)
          Cookies.set(LITE_ADVERTISE, id)

          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          const route = user.full_record ? `${prefix}/dashboard` : '/register'
          router.replace(route)
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          setIsLoading(false)
        })
    }
    const handleUpdateProfileSubmit = (data) => {
      sweetLoadingRef.current = sweetLoading('', 'Estamos actualizando tu perfil. ')
      const { account, ...rest } = data
      const form = {
        ...rest,
        account: account.id
      }
      updateAsync(form)
        .then((response) => {
          const { data: { record: user } } = response
          Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
          const action = {
            type: authTypes.login,
            payload: {
              user
            }
          }
          dispatch(action)
          router.push('/system/my-account/profile')
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          sweetLoadingRef.current.close()
          sweetLoadingRef.current = null
        })
    }
    const onLogOut = () => {
      const action = {
        type: authTypes.logout
      }
      dispatch(action)
      setTimeout(function () {
        router.push('/login')
      }, 200)
    }

    const onPayMembershipSubmit = (paymentCard, res, setShowPaymentAccepted) => {
      paymentCard(res.data.record.id).then((response) => {
        // console.log('response', response);
        const {
          data: {
            user,
            token: {
              access_token: accessToken
            }
            , membership: { id }
          }
        } = response
        Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
        Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
        Cookies.set(TOKEN, encrypt(accessToken))
        // console.log('here');
        Cookies.set(RECOVERY_ADVERTISE, id)
        Cookies.set(LITE_ADVERTISE, id)
        const action = {
          type: authTypes.login,
          payload: {
            user
          }
        }
        dispatch(action)
        router.push('/system/dashboard')
      }).finally(() => {
        setShowPaymentAccepted(false)
      })
    }
    const onMembershipSubmit = (paymentCard, formData, membership, setShowPaymentAccepted) => {
      paymentCard(formData, membership).then((response) => {
        if (formData.type === 2) {
          response.data.membership = { id: 2 }
          setResponseLogOx(response)
        }
        if (formData.type === 3) {
          response.data.membership = { id: 3 }
          setResponseLogSp(response)
        }
      }).finally(() => {
        // setShowPaymentAccepted(false)
      })
    }
    const onAllReadySubmit = (response) => {
      // console.log('paso 99', response);
      const {
        data: {
          user,
          token: {
            access_token: accessToken
          }
          , membership: { id }
        }
      } = response
      Cookies.set(AUTHENTICATED_USER, encrypt(JSON.stringify(user)))
      Cookies.set(AUTHENTICATED, encrypt(LOGGED_IN))
      Cookies.set(TOKEN, encrypt(accessToken))
      // console.log('here');
      Cookies.set(RECOVERY_ADVERTISE, id)
      Cookies.set(LITE_ADVERTISE, id)
      const action = {
        type: authTypes.login,
        payload: {
          user
        }
      }
      dispatch(action)
      router.push('/system/dashboard')
    }
    return {
      handleRegisterSubmit,
      handleSkipValidationSubmit,
      handleVerifyCodeSubmit,
      onFreeMembershipSubmit,
      onTryMembershipSubmit,
      handleLoginSubmit,
      handleUpdateProfileSubmit,
      onLogOut,
      onPayMembershipSubmit,
      handleChangePassword,
      onMembershipSubmit,
      onAllReadySubmit
    }
  }, [router])

  // useEffect(() => {
    // console.log('2logued');
    // if (!authState.isLoggedIn) {
    //   onSubmit.onLogOut()
    // }
  // }, [authState, onSubmit])
  const onClick = useMemo(() => {
    const handleResendCodeClick = () => {
      setIsLoading(true)
      sweetLoadingRef.current = sweetLoading('', 'Estamos procesando tu solicitud de reenvió de código...')
      resendCodeAsync()
        .then((response) => {
          const { data: { message } } = response
          sweetSuccess(message)
        }).catch((e) => {
          sweetError('', e)
        }).finally(() => {
          setIsLoading(false)
        }).finally(() => {
          setIsLoading(false)
          sweetLoadingRef.current.close()
          sweetLoadingRef.current = null
        })
    }
    return {
      handleResendCodeClick
    }
  }, [])

  const props = useMemo(() => ({
    ...authState,
    onSubmit,
    onClick,
    isLoading,
    currentBranch,
    setCurrentBranch,
    dispatch,
    onOxxo,
    onSpei,
    responseLogOx,
    responseLogSp
  }), [authState, currentBranch, isLoading, onClick, onSubmit, responseLogOx, responseLogSp])

  return (
    <AuthContext.Provider value={props}>
      {children}
    </AuthContext.Provider>
  )
}
