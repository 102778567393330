import '@/styles/globals.css'
import 'sweetalert2/src/sweetalert2.scss'
import '@/styles/fonts.scss'
import '@/styles/buttons.scss'
import '@/styles/icons.scss'
import '@/styles/layout.scss'
import '@/styles/primereact/button.scss'
import '@/styles/primereact/dataTable.scss'
import '@/styles/primereact/input.scss'
import '@/styles/primereact/tooltip.scss'
import '@/styles/primereact/calendar.scss'
import '@/styles/primereact/dialog.scss'
import '@/styles/primereact/dropdown.scss'
import '@/styles/primereact/OverlayPanel.scss'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'swiper/css'

import { AuthProvider } from '@/context/AuthProvider'
import { useRouter } from 'next/router'
import { SystemProvider } from '@/context/auth/SystemProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false,
      gcTime: 0
    }
  }
})

export default function App ({ Component, pageProps }) {
  const router = useRouter()

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        {
          router.pathname.startsWith('/system')
            ? (
              <SystemProvider>
                <Component {...pageProps} />
              </SystemProvider>
              )
            : (
              <Component {...pageProps} />
              )
        }
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
