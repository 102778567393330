import { api } from '@/services/axios/api'

export async function skipValidationAsync (formData) {
  return new Promise((resolve, reject) => {
    api.post('user/register/skip-validation', formData)
      .then(response => resolve(response.data))
      .catch(error => {
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        reject(errorMessage)
      })
  })
}
