const TOKEN = process.env.NEXT_PUBLIC_TOKEN
const AUTHENTICATED = process.env.NEXT_PUBLIC_AUTHENTICATED
const LOGGED_IN = process.env.NEXT_PUBLIC_LOGGED_IN
const AUTHENTICATED_USER = process.env.NEXT_PUBLIC_AUTHENTICATED_USER
const RECOVERY_PASSWORD = process.env.NEXT_PUBLIC_RECOVERY_PASSWORD
const RECOVERY_ADVERTISE = process.env.NEXT_PUBLIC_RECOVERY_ADVERTISE
const LITE_ADVERTISE = process.env.NEXT_PUBLIC_LITE_ADVERTISE
const CURRENT_BRANCH = process.env.NEXT_PUBLIC_CURRENT_BRANCH
const ALL_BRANCH = process.env.NEXT_PUBLIC_ALL_BRANCH
export {
  TOKEN,
  AUTHENTICATED,
  CURRENT_BRANCH,
  ALL_BRANCH,
  LOGGED_IN,
  RECOVERY_PASSWORD,
  RECOVERY_ADVERTISE,
  LITE_ADVERTISE,
  AUTHENTICATED_USER
}
