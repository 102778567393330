export const cleanFormData = (formData) => {
  const cleanedFormData = Object.fromEntries(
    Object.entries(formData).filter(([key, value]) => value !== '' && value !== null)
  )
  return cleanedFormData
}

export const getCurrentDate = () => {
  return new Date()
}

export const getFirstDayOfMonth = (currentDate = new Date()) => {
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
}

export const roundNumber = (number, decimal = 2) => {
  return parseFloat(Number.parseFloat(number).toFixed(decimal))
}
