import { authTypes } from '@/types/authTypes'
import { CURRENT_BRANCH, ALL_BRANCH, AUTHENTICATED, AUTHENTICATED_USER, RECOVERY_ADVERTISE, RECOVERY_PASSWORD, TOKEN } from '@/utils/constants'
import Cookies from 'js-cookie'

export const authReducer = (state = {}, action) => {
  // console.log('action', action)
  // console.log('state', state)
  switch (action.type) {
    case authTypes.login:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user
      }
    case authTypes.logout:
      Cookies.remove(TOKEN)
      Cookies.remove(AUTHENTICATED)
      Cookies.remove(AUTHENTICATED_USER)
      Cookies.remove(CURRENT_BRANCH)
      Cookies.remove(ALL_BRANCH)
      Cookies.remove(RECOVERY_PASSWORD)
      Cookies.remove(RECOVERY_ADVERTISE)
      return {
        ...state,
        isLoggedIn: false
      }
    default:
      return state
  }
}
