import { get } from '../../api'

const baseUrl = 'user'

export async function userAsync () {
  return await get(`${baseUrl}`)
}
export async function sidebarAsync () {
  return await get(`${baseUrl}/sidebar`)
}
