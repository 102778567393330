import Swal from 'sweetalert2/dist/sweetalert2.all.min.js'

const sweetSuccess = (title) => {
  Swal.fire({
    icon: 'success',
    title,
    showConfirmButton: false,
    timer: 2000
  })
}

const sweetError = (title, text) => {
  Swal.fire({
    icon: 'error',
    title,
    html: Object.prototype.toString.call(text) === '[object Object]'
      ? `<ul>${Object.entries(text).map(([key, item]) => {
                return item.map((item) => {
                    return `<li>${item}</li>`
                }).join('')
            })
            .join('')}</ul>`
      : `<li>${text}</li>`
  })
}

const sweetInfo = (title, text) => {
  Swal.fire({
    icon: 'info',
    title,
    html: Object.prototype.toString.call(text) === '[object Object]'
      ? `<ul>${Object.entries(text).map(([key, item]) => {
                return item.map((item) => {
                    return `<li>${item}</li>`
                }).join('')
            })
            .join('')}</ul>`
      : `<li>${text}</li>`
  })
}

const sweetLoading = (title, message) => {
  const swalInstance = Swal.fire({
    title,
    text: message,
    showConfirmButton: false,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading()
    }
  })
  const close = () => {
    swalInstance.close()
  }
  return {
    close
  }
}

const sweetDelete = (functionAccept, textDelete = '') => {
  Swal.fire({
    title: '¿Estas seguro que desea eliminar?',
    text: textDelete,
    showDenyButton: true,
    confirmButtonText: 'Eliminar',
    denyButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      functionAccept()
    } else if (result.isDenied) {
      Swal.close()
    }
  })
}

export {
  sweetError,
  sweetLoading,
  sweetSuccess,
  sweetDelete,
  sweetInfo
}
