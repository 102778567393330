import { post } from '../../api'

export async function updateAsync (formData) {
  return await post('account/profile', formData)
}

export async function resendCodeAsync (formData) {
  return await post('account/resend-code', formData)
}

export async function verifyCodeAsync (formData) {
  return await post('account/verify-code', formData)
}
