import { useMemo, useState, useEffect, useContext, useReducer } from 'react'
import { SystemContext } from './SystemContext'
import { useQuery } from '@tanstack/react-query'
import { branchesAsync } from '@/services/axios/authenticated/general'
import Cookies from 'js-cookie'
import { ALL_BRANCH, CURRENT_BRANCH } from '@/utils/constants'
import { AuthContext } from '../AuthContext'
import { sidebarAsync, userAsync } from '@/services/axios/authenticated/user'

import ayuda from '@/../public/img/sidebar/ayuda.svg'
import logOut from '@/../public/img/sidebar/cerrar_sesion.svg'
import inicio from '@/../public/img/sidebar/inicio.svg'
import legales from '@/../public/img/sidebar/legales.svg'
import myAccount from '@/../public/img/sidebar/mi_cuenta.svg'
import perfil from '@/../public/img/sidebar/perfil.svg'
import privacyPolicies from '@/../public/img/sidebar/politicas_privacidad.svg'
import termsConditions from '@/../public/img/sidebar/terminos_condiciones.svg'
import { authReducer } from '../authReducer'
import { initial } from '../AuthProvider'
// import { AuthContextAuth } from '@/context/AuthContext'

const sidebarLinks = [

  {
    name: 'Inicio',
    path: 'dashboard',
    icon: {
      url: inicio
    },
    submenus: []
  },
  {
    name: 'Mi cuenta',
    path: '#',
    icon: {
      url: myAccount
    },
    submenus: [
      {
        name: 'Perfil',
        path: 'my-account/profile',
        icon: {
          url: perfil
        }
      }
    ]
  },
  {
    name: 'Legales',
    path: '#',
    icon: {
      url: legales
    },
    submenus: [
      {
        name: 'Términos y condiciones',
        path: 'legal/terms-conditions',
        icon: {
          url: termsConditions
        }
      },
      {
        name: 'Políticas de privacidad',
        path: 'legal/privacy-policy',
        icon: {
          url: privacyPolicies
        }
      }
    ]
  },
  {
    name: 'Ayuda',
    path: 'help',
    icon: {
      url: ayuda
    },
    submenus: []
  },
  {
    name: 'Cerrar sesión',
    path: '/',
    icon: {
      url: logOut
    },
    submenus: [],
    logout: true
  }
]

export const SystemProvider = (props) => {
  const [authState] = useReducer(authReducer, {}, initial)
  const [branchesDropdown, setBranchesDropdown] = useState([])
  const [sidebarMenu, setSidebarMenu] = useState(sidebarLinks)
  // const { onSubmit: handleLogout } = useContext(AuthContext)

  const { currentBranch, setCurrentBranch, onSubmit } = useContext(AuthContext)

  const [systemPermissions, setSystemPermissions] = useState([])
  const [newBranch, setNewBranch] = useState(false)

  const { data: branchResults, isLoading: isLoadingBranches, refetch: reFresh } = useQuery({
    queryKey: ['branches'],
    queryFn: (form)=>branchesAsync(form, () => onSubmit.onLogOut())
  })

  // useEffect(() => {
  //   console.log('1logued', authState.isLoggedIn);
  //   if (!authState.isLoggedIn) {
  //     onSubmit.onLogOut()
  //   }
  // }, [authState, onSubmit])

  useEffect(() => {
    if (newBranch) {
      // console.log('entro y nueva branch');
      setNewBranch(false)
      reFresh()
    }
  }, [newBranch])

  const { data: sidebarResults } = useQuery({
    queryKey: ['sidebar'],
    queryFn: (userAsync)
  })

  useEffect(() => {
    if (sidebarResults) {
      const menu = Object.values(sidebarResults.data.sidebar)
      menu.length > 0 && setSidebarMenu(menu)
      setSystemPermissions(sidebarResults.data.permissions)
    }
  }, [sidebarResults])

  useEffect(() => {
    if (branchResults) {
      if (branchResults.data.records.length) {
        const currentBranch = branchResults.data.records.filter(record => record.default === 1)[0] || branchResults.data.records[0]
        const newCurrentBranch = {
          id: currentBranch.id,
          text: currentBranch.text
        }
        setCurrentBranch(newCurrentBranch.id)
        Cookies.set(CURRENT_BRANCH, JSON.stringify(newCurrentBranch))
        const allBranches = branchResults.data.records
        setBranchesDropdown(allBranches)
        Cookies.set(ALL_BRANCH, JSON.stringify(allBranches))
      }
    }
  }, [branchResults, setCurrentBranch])

  const onChange = useMemo(() => {
    const handleCurrentBranch = (e) => {
      const { id } = e.value
      setCurrentBranch(id)
      Cookies.set(CURRENT_BRANCH, JSON.stringify(e.value))
    }
    return {
      handleCurrentBranch
    }
  }, [setCurrentBranch])
  const value = useMemo(() => ({
    branchesDropdown,
    isLoadingBranches,
    onChange,
    currentBranch,
    sidebarMenu,
    systemPermissions,
    setNewBranch
  }), [branchesDropdown, currentBranch, isLoadingBranches, onChange, sidebarMenu, systemPermissions, setNewBranch])

  return (
    <SystemContext.Provider value={value}>
      {props.children}
    </SystemContext.Provider>
  )
}
